.disappeared
    display: none !important
    // to hide text : bootstrap class : sr-only

// For PrintJs
iframe
    border: 0

.app
    display: flex
    flex-direction: row
    min-height: 100vh
    font-family: $font-family-base
    color: $grey
    position: relative

    .page
        min-height: 100vh

    // Link
    a
        transition: all 0.3s
        &.extended-link:before
            content: ''
            position: absolute
            display: block
            left: 0
            right: 0
            top: 0
            bottom: 0
            z-index: 10

    main a
        text-decoration: underline
        color: inherit
        &:hover,
        &:focus,
        &:active
            color: $blue

    main h2 a
        text-decoration: none
        &:hover,
        &:focus,
        &:active
            text-decoration: none

    // Text
    strong, b
        font-weight: 600
        
    .medium
        font-weight: $medium

    blockquote
        font-style: italic
        font-size: 24px

    // Tools
    select
        -webkit-appearance: none
        -moz-appearance: none
        appearance: none
        padding-right: 22px !important
        background: url('images/pictogrammes/arrow-grey.svg') no-repeat right 5px center
        background-size: 12px auto

    .standfirst
        margin-bottom: 15px
        .item-selector
            display: inline-block
            margin-bottom: 15px
            margin-top: 0
            margin-right: 25px
            &:last-child
                margin-right: 0
        .toggle-btn-wrapper
            margin-bottom: 0
    .standfirst.toggle-plus-select
        text-align: center
        .toggle-btn-wrapper
            margin-top: 10px
            margin-bottom: 10px
    .item-selector
        align-self: flex-start
        margin-top: 10px
        label
            padding-right: 5px
            margin: 0
        select
            background-color: $light_grey
            border-radius: 10px
            color: $grey
            border: 0
            padding: 5px 5px 3px
    .item-select-disable
        @extend .item-selector

    // Log
    .user-log-wrapper
        position: fixed
        z-index: 99
        top: $header_height
        left: $aside_width
        right: 0
        padding: 15px 15px 0
        text-align: center
        max-width: 1170px
        margin-left: auto
        margin-right: auto
        transition: all 0.3s
        .user-log
            display: inline-block
            box-shadow: 0 0 3px 0 rgba(0,0,0,0.3)
            padding: 8px 30px
            border-radius: 10px
            opacity: 1
            transition: all 0.3s
            &.success
                color: #155724
                background-color: #d4edda
                border: 1px solid #c3e6cb
            &.error
                color: #721c24
                background-color: #f8d7da
                border-color: #f5c6cb
        &.hidden .user-log
            opacity: 0
        @media screen and (max-width: $screen-xl)
            left: 0
            max-width: 100%
    &.unscrollable .user-log-wrapper,
    &.not-logged-page .user-log-wrapper
        left: 0
        top: 15px
        z-index: 1000

    // Main Content
    .page
        flex-grow: 1 // width 100% : parent flex direction row
        min-width: 0
        main
            height: calc(100% - #{$header_height})

    &.unscrollable
        overflow: hidden
        height: 100vh

@media screen and (max-width: $screen-sm), screen and (max-height: $screen-xs) and (max-width: $screen-lg)
    .app
        display: block
        main
            height: auto
            h3
                font-size: 20px
        .standfirst
            padding-left: 15px
        blockquote
            font-size: 14px
