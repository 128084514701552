// My Data
.app .my-data.grid.grid-3
    grid-template-areas: "child1 child2" "child3 child3"
    grid-template-columns: 1fr 1fr
    grid-template-rows: 1fr auto
    .table-lots .td-label
        min-width: 50px

.app .table-lease
    th
        white-space: nowrap
    tr.title
        td, th
            background-color: white
    .file
        display: inline-block
        .picto-table
            height: 33px
            padding: 3px 0

.app .table-leases
    tbody tr
        cursor: pointer

.app #lease-modal .modal
    width: 1000px

@media screen and (max-width: $screen-xl)
    .app .my-data.grid.grid-3
        grid-template-areas: "child1" "child2" "child3"
        grid-template-columns: 1fr
        grid-template-rows: auto

@media screen and (max-width: $screen-md)
    .app .table-lease th
        white-space: normal