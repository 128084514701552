.app .card
    height: 100%
    border: 2px solid $light_grey
    border-radius: 10px
    font-size: 18px
    overflow: hidden
    &.unscrollable
        max-height: none !important
        .card-body
            overflow: auto

    .card-header
        flex-shrink: 0
        padding: 8px 25px 8px 15px
        border-bottom: 2px solid $light_grey
        border-radius: 10px 10px 0 0
        background-color: white
        &.composed-card-header
            display: flex
            align-items: center
            justify-content: space-between
            flex-wrap: wrap
            h2
                margin-right: 15px
            em
                margin-top: 15px
        &.card-header-with-back
            display: flex
            align-items: center
            h2
                margin-right: 15px
            em
                margin: 15px 10px 0 15px
            @media screen and (max-width: $screen-sm)
                em, 
                .item-select-disable
                    display: none
        h1,
        h2
            line-height: 1
            margin: 0
            border-left: 5px solid $blue
            font-size: 30px
            color: $grey
            text-transform: uppercase
            font-weight: $medium
        h1
            padding: 10px 0 5px 15px
        h2
            padding: 10px 0 2px 15px

    .card-row
        background-color: transparentize($blue, 0.8)
        border-bottom: 2px solid $light_grey
        padding: 15px 50px

    .card-body
        padding: 15px 25px 0
        border-radius: 0 0 10px 10px
        // Edge fallback
        overflow: auto
        overflow: hidden auto
        scrollbar-color: $light_grey white
        scrollbar-width: thin
        &::-webkit-scrollbar
            background-color: white
            height: 12px
            width: 12px
            border-radius: 6px
        &::-webkit-scrollbar-thumb
            background-color: $light_grey
            border-radius: 6px
        &.table-wrapper
            overflow: auto
            .btn-wrapper
                padding-top: 20px
        > :last-child
            padding-bottom: 15px
        > .table:last-child
            padding-bottom: 0
            margin-bottom: 15px

    .card-footer 
        border-top: 2px solid $light_grey
        background-color: white
        padding: 8px 15px
        font-size: 12px
        a
            color: $grey
            text-decoration: underline
            margin-right: 15px

@media screen and (max-width: $screen-xl)
    .app .card .card-body
        padding: 15px 15px 0
        
@media screen and (max-width: $screen-sm), screen and (max-height: $screen-sm) and (max-width: $screen-lg)
    .app .card
        height: auto
        min-height: 1px
        border: 0
        border-radius: 0
        font-size: 14px
        overflow: auto
        border-top: 2px solid $light_grey
        padding-top: 10px
        &:first-child
            border-top: 0
        &:last-child
            margin-bottom: 0
        .card-row
            padding: 15px 25px
        .card-header
            border-bottom: 2px solid $pale_grey
            border-radius: 0
            h1,
            h2
                font-size: 24px
        .card-body
            border-radius: 0
            padding: 15px
            overflow: auto
            &::-webkit-scrollbar
                height: 7px
                width: 7px
            &::-webkit-scrollbar-thumb
                -webkit-border-radius: 1ex
                border-radius: 4px
            &.table-wrapper
                padding: 15px 0 0
                p
                    padding: 0 15px
                .btn-wrapper
                    padding-bottom: 15px
            > :last-child
                padding-bottom: 0
        > :last-child
            margin-bottom: 0
            .card-body
                padding-bottom: 0