.app .my-info .card
    font-size: 22px

    .btn-menu
        display: inline-block
        background-color: $light_grey
        border-radius: 10px
        overflow: hidden
        line-height: 1
        .btn:not(:first-child)
            margin-left: -18px
            padding-left: 45px

    .my-info-form
        padding-bottom: 25px
        label
            width: 140px
        .fake-label
            font-weight: $medium
        .cgu
            margin-bottom: 20px
            padding-top: 10px
            a
                padding-right: 0

        .form-item-image-wrapper
            .fake-label
                width: 140px
            .form-item-image
                flex-grow: 1
                min-width: 215px
                border: 1px solid $light_grey
                border-radius: 5px
                padding: 20px 15px
                font-size: 16px

    .my-info-password
        .form-item-list
            .form-item label
                width: 270px
                margin-bottom: 0
            .form-item input
                padding: 0 10px
                height: 35px

@media screen and (max-width: $screen-xs)
    .app .my-info .card 
        .btn-menu
            display: inline-block
            border-radius: 0
            background-color: transparent
            margin-bottom: -10px
            .btn
                margin-right: 15px
                margin-bottom: 10px
            .btn:not(:first-child)
                margin-left: 0
                padding-left: 35px

        .my-info-form 
            .table
                margin-bottom: 25px
            .form-item-image-wrapper
                display: block
                .fake-label
                    margin-bottom: 5px

@media screen and (max-width: $screen-sm), screen and (max-height: $screen-xs) and (max-width: $screen-lg)
    .app .my-info .card 
        font-size: 14px
        .my-info-form  .form-item-image-wrapper .fake-label
            font-size: 18px 