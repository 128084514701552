// MAVEN PRO
// ======
// Black
@font-face {
    font-family: 'mavenpro';
    src: url('font/mavenpro-black.eot');
    src: url('font/mavenpro-black.eot?#iefix') format('embedded-opentype'),
         url('font/mavenpro-black.woff2') format('woff2'),
         url('font/mavenpro-black.woff') format('woff'),
         url('font/mavenpro-black.ttf') format('truetype'),
         url('font/mavenpro-black.svg#maven_problack') format('svg');
    font-weight: 700;
    font-style: normal;
}
// Bold
@font-face {
    font-family: 'mavenpro';
    src: url('font/mavenpro-bold.eot');
    src: url('font/mavenpro-bold.eot?#iefix') format('embedded-opentype'),
         url('font/mavenpro-bold.woff2') format('woff2'),
         url('font/mavenpro-bold.woff') format('woff'),
         url('font/mavenpro-bold.ttf') format('truetype'),
         url('font/mavenpro-bold.svg#maven_probold') format('svg');
    font-weight: 600;
    font-style: normal;
}
// Medium
@font-face {
    font-family: 'mavenpro';
    src: url('font/mavenpro-medium.eot');
    src: url('font/mavenpro-medium.eot?#iefix') format('embedded-opentype'),
         url('font/mavenpro-medium.woff2') format('woff2'),
         url('font/mavenpro-medium.woff') format('woff'),
         url('font/mavenpro-medium.ttf') format('truetype'),
         url('font/mavenpro-medium.svg#maven_promedium') format('svg');
    font-weight: 500;
    font-style: normal;
}
// Regular
@font-face {
    font-family: 'mavenpro';
    src: url('font/mavenpro-regular.eot');
    src: url('font/mavenpro-regular.eot?#iefix') format('embedded-opentype'),
         url('font/mavenpro-regular.woff2') format('woff2'),
         url('font/mavenpro-regular.woff') format('woff'),
         url('font/mavenpro-regular.ttf') format('truetype'),
         url('font/mavenpro-regular.svg#maven_proregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

// FUTURA
// ======
// Light
@font-face {
    font-family: 'futura';
    src: url('font/futura-light.eot');
    src: url('font/futura-light.eot?#iefix') format('embedded-opentype'),
         url('font/futura-light.woff2') format('woff2'),
         url('font/futura-light.woff') format('woff'),
         url('font/futura-light.ttf') format('truetype'),
         url('font/futura-light.svg#futura-lightregular') format('svg');
    font-weight: 300;
    font-style: normal;

}
// Regular
@font-face {
    font-family: 'futura';
    src: url('font/futura-oblique.eot');
    src: url('font/futura-oblique.eot?#iefix') format('embedded-opentype'),
         url('font/futura-oblique.woff2') format('woff2'),
         url('font/futura-oblique.woff') format('woff'),
         url('font/futura-oblique.ttf') format('truetype'),
         url('font/futura-oblique.svg#futura-oblique') format('svg');
    font-weight: 400;
    font-style: normal;
}
//Medium
@font-face {
    font-family: 'futura';
    src: url('font/futura-medium.eot');
    src: url('font/futura-medium.eot?#iefix') format('embedded-opentype'),
         url('font/futura-medium.woff2') format('woff2'),
         url('font/futura-medium.woff') format('woff'),
         url('font/futura-medium.ttf') format('truetype'),
         url('font/futura-medium.svg#futuramedium') format('svg');
    font-weight: 500;
    font-style: normal;
}
// Extend ~= medium
// @font-face {
//     font-family: 'futuraextended';
//     src: url('font/futura-extended.eot');
//     src: url('font/futura-extended.eot?#iefix') format('embedded-opentype'),
//          url('font/futura-extended.woff2') format('woff2'),
//          url('font/futura-extended.woff') format('woff'),
//          url('font/futura-extended.ttf') format('truetype'),
//          url('font/futura-extended.svg#futuraextendedregular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }
// Compressed (weird)
// @font-face {
//     font-family: 'futura';
//     src: url('font/futura-light-font.eot');
//     src: url('font/futura-light-font.eot?#iefix') format('embedded-opentype'),
//          url('font/futura-light-font.woff2') format('woff2'),
//          url('font/futura-light-font.woff') format('woff'),
//          url('font/futura-light-font.ttf') format('truetype'),
//          url('font/futura-light-font.svg#futuralight') format('svg');
//     font-weight: 300;
//     font-style: normal;
// }