.app .btn
    position: relative
    padding: 3px 35px 0
    border: 0
    border-radius: 10px
    background-color: transparent
    color: white
    white-space: normal
    font-size: 19px
    font-weight: $medium
    text-decoration: none
    transition: all 0.3s
    overflow: hidden
    z-index: 1
    &:before
        content: ""
        display: block
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: darken($blue, 15)
        transform: translateX(-101%)
        transition: all 0.35s
        z-index: -1
    &:after
        content: ""
        display: block
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: $blue
        transition: all 0.3s
        z-index: -1
    &:hover,
    &:focus,
    &:active,
    &.active
        background-color: transparent
        color: white
        &:before
            transform: translateX(0)
            transition: all 0.25s
        &:after
            transform: translateX(101%)
    &.active
        z-index: 2
    &.light-btn
        color: $blue
        background-color: white
        &:hover,
        &:focus,
        &:active,
        &.active
            color: white
            background-color: transparent
        &:after
            background-color: white
        &:before
            background-color: $blue
        
    &.grey
        color: white
        &:after
            background-color: $grey
        &:hover,
        &:focus,
        &:active,
        &.active
            color: $grey
        &:before
            background-color: $light_grey

    &.btn-orange
        background-color: $orange
        &:after
            background-color: $orange
        &:before
            background-color: darken($orange, 15)

    &.btn-large
        &:before
            transform: translateX(-100%)
            transition: all 0.45s
        &:after
            transition: all 0.45s
        &:hover,
        &:focus,
        &:active,
        &.active
            &:before
                transform: translateX(0)
            &:after
                transform: translateX(100%)

    &.btn-image
        display: inline-flex
        align-items: center
        padding-left: 25px
        img
            display: inline
            height: 17px
            width: auto
            margin-right: 15px

.back-btn
    position: relative
    background: url('images/pictogrammes/back-arrow-grey.svg') no-repeat left center transparent
    background-size: 20px
    border: 0
    cursor: pointer
    padding: 0
    line-height: 1
    width: 20px
    height: 20px
    text-indent: -9999px
    margin-right: 20px
    &:before
        content: ''
        background: url('images/pictogrammes/back-arrow-yellow.svg') no-repeat left center transparent
        background-size: 20px
        position: absolute
        left: 0
        top: 0
        bottom: 0
        z-index: 1
        width: 0
        transition: all 0.5s
    &:hover,
    &:focus,
    &:active
        &:before
            width: 20px

.btn-print
    display: block
    background-color: transparent
    border: 0
    width: 45px
    margin-left: auto
    cursor: pointer
    position: relative
    padding: 0
    &:before
        content: ''
        position: absolute
        background-color: white
        height: 4px
        top: 45px
        left: 0
        right: 0
        transition: all 0.3s
    &:hover,
    &:focus,
    &:active
        &:before
            bottom: auto
            top: -4px
    img
        max-width: 100%
        height: auto

.btn-wrapper
    text-align: center

.toggle-btn-wrapper
    text-align: center
    margin-bottom: 15px
    .btn
        margin-right: 15px
        margin-bottom: 15px
        padding: 0 35px 0 15px
        img
            width: 12px
            height: 12px
            margin-right: 10px
        span
            vertical-align: middle
        &.active
            cursor: mouse

.card-btn
    &:hover,
    &:focus,
    &:active
        .btn
            color: $grey
            background-color: transparent
            &:before
                transform: translateX(0)
            &:after
                transform: translateX(100%)
    .card-body
        display: flex
        align-items: center
        justify-content: center

.app .property-teaser
    .btn
        color: white
        background-color: $orange
    .btn:after
        background-color: $orange
    .btn:hover,
    .btn:focus,
    .btn:active,
    .btn.active
        color: $orange
    .btn:before
        background-color: white
    .btn.light-btn
        color: $orange
        background-color: white
    .btn.light-btn:hover,
    .btn.light-btn:focus,
    .btn.light-btn:active,
    .btn.light-btn.active
        color: white
    .btn.light-btn:before
        background-color: $orange
    .btn.grey
        color: white
        &:after
            background-color: $grey
        &:hover,
        &:focus,
        &:active,
        &.active
            color: $grey
        &:before
            background-color: $light_grey

    &:hover,
    &:focus,
    &:active
        .btn
            color: $orange
            &:before
                transform: translateX(0)
            &:after
                transform: translateX(100%)
            &.light-btn
                color: white

.app .dropdown 
    .btn
        padding: 5px 10px
    .dropdown-menu.right
        right: 0
        left: auto

@media (max-width: $screen-sm)
    .app .dropdown .dropdown-menu
        width: 90vw
        .dropdown-item
            white-space: normal