.app .dashboard
    &.parent-graph.grid
        grid-template-rows: 1fr auto

    .notification
        position: relative
        padding-left: 40px
        &:before
            content: ""
            display: block
            position: absolute
            top: 0
            left: 0
            transform: translateY(50%)
            height: 20px
            width: 20px
            background-color: $blue
            border-radius: 50%

        h2
            margin-bottom: 0
            font-weight: $medium
            font-size: 20px
            text-transform: uppercase

        &:last-child p
            margin-bottom: 0

@media screen and (max-width: $screen-sm)
    .app .dashboard
        .notification
            padding-left: 30px
            &:before
                height: 15px
                width: 15px
            h2
                font-size: 18px

@media screen and (max-height: $screen-sm)
    .app .dashboard .notification h2
        font-size: 18px

@media screen and (max-width: $screen-xs)
    .app .dashboard
        .notification
            padding-left: 20px
            &::before
                left: -5px
                width: 12px
                height: 12px