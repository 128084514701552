@font-face {
  font-family: 'mavenpro';
  src: url("font/mavenpro-black.eot");
  src: url("font/mavenpro-black.eot?#iefix") format("embedded-opentype"), url("font/mavenpro-black.woff2") format("woff2"), url("font/mavenpro-black.woff") format("woff"), url("font/mavenpro-black.ttf") format("truetype"), url("font/mavenpro-black.svg#maven_problack") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'mavenpro';
  src: url("font/mavenpro-bold.eot");
  src: url("font/mavenpro-bold.eot?#iefix") format("embedded-opentype"), url("font/mavenpro-bold.woff2") format("woff2"), url("font/mavenpro-bold.woff") format("woff"), url("font/mavenpro-bold.ttf") format("truetype"), url("font/mavenpro-bold.svg#maven_probold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'mavenpro';
  src: url("font/mavenpro-medium.eot");
  src: url("font/mavenpro-medium.eot?#iefix") format("embedded-opentype"), url("font/mavenpro-medium.woff2") format("woff2"), url("font/mavenpro-medium.woff") format("woff"), url("font/mavenpro-medium.ttf") format("truetype"), url("font/mavenpro-medium.svg#maven_promedium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'mavenpro';
  src: url("font/mavenpro-regular.eot");
  src: url("font/mavenpro-regular.eot?#iefix") format("embedded-opentype"), url("font/mavenpro-regular.woff2") format("woff2"), url("font/mavenpro-regular.woff") format("woff"), url("font/mavenpro-regular.ttf") format("truetype"), url("font/mavenpro-regular.svg#maven_proregular") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'futura';
  src: url("font/futura-light.eot");
  src: url("font/futura-light.eot?#iefix") format("embedded-opentype"), url("font/futura-light.woff2") format("woff2"), url("font/futura-light.woff") format("woff"), url("font/futura-light.ttf") format("truetype"), url("font/futura-light.svg#futura-lightregular") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'futura';
  src: url("font/futura-oblique.eot");
  src: url("font/futura-oblique.eot?#iefix") format("embedded-opentype"), url("font/futura-oblique.woff2") format("woff2"), url("font/futura-oblique.woff") format("woff"), url("font/futura-oblique.ttf") format("truetype"), url("font/futura-oblique.svg#futura-oblique") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'futura';
  src: url("font/futura-medium.eot");
  src: url("font/futura-medium.eot?#iefix") format("embedded-opentype"), url("font/futura-medium.woff2") format("woff2"), url("font/futura-medium.woff") format("woff"), url("font/futura-medium.ttf") format("truetype"), url("font/futura-medium.svg#futuramedium") format("svg");
  font-weight: 500;
  font-style: normal;
}

.disappeared {
  display: none !important;
}

iframe {
  border: 0;
}

.app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  min-height: 100vh;
  font-family: "mavenpro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #606060;
  position: relative;
}

.app .page {
  min-height: 100vh;
}

.app a {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.app a.extended-link:before {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
}

.app main a {
  text-decoration: underline;
  color: inherit;
}

.app main a:hover, .app main a:focus, .app main a:active {
  color: #336699;
}

.app main h2 a {
  text-decoration: none;
}

.app main h2 a:hover, .app main h2 a:focus, .app main h2 a:active {
  text-decoration: none;
}

.app strong, .app b {
  font-weight: 600;
}

.app .medium {
  font-weight: 500;
}

.app blockquote {
  font-style: italic;
  font-size: 24px;
}

.app select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 22px !important;
  background: url("images/pictogrammes/arrow-grey.svg") no-repeat right 5px center;
  background-size: 12px auto;
}

.app .standfirst {
  margin-bottom: 15px;
}

.app .standfirst .item-selector, .app .standfirst .item-select-disable {
  display: inline-block;
  margin-bottom: 15px;
  margin-top: 0;
  margin-right: 25px;
}

.app .standfirst .item-selector:last-child, .app .standfirst .item-select-disable:last-child {
  margin-right: 0;
}

.app .standfirst .toggle-btn-wrapper {
  margin-bottom: 0;
}

.app .standfirst.toggle-plus-select {
  text-align: center;
}

.app .standfirst.toggle-plus-select .toggle-btn-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.app .item-selector, .app .item-select-disable {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-top: 10px;
}

.app .item-selector label, .app .item-select-disable label {
  padding-right: 5px;
  margin: 0;
}

.app .item-selector select, .app .item-select-disable select {
  background-color: #cecece;
  border-radius: 10px;
  color: #606060;
  border: 0;
  padding: 5px 5px 3px;
}

.app .user-log-wrapper {
  position: fixed;
  z-index: 99;
  top: 70px;
  left: 300px;
  right: 0;
  padding: 15px 15px 0;
  text-align: center;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.app .user-log-wrapper .user-log {
  display: inline-block;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  padding: 8px 30px;
  border-radius: 10px;
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.app .user-log-wrapper .user-log.success {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

.app .user-log-wrapper .user-log.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.app .user-log-wrapper.hidden .user-log {
  opacity: 0;
}

@media screen and (max-width: 1200px) {
  .app .user-log-wrapper {
    left: 0;
    max-width: 100%;
  }
}

.app.unscrollable .user-log-wrapper,
.app.not-logged-page .user-log-wrapper {
  left: 0;
  top: 15px;
  z-index: 1000;
}

.app .page {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-width: 0;
}

.app .page main {
  height: calc(100% - 70px);
}

.app.unscrollable {
  overflow: hidden;
  height: 100vh;
}

@media screen and (max-width: 576px), screen and (max-height: 448px) and (max-width: 992px) {
  .app {
    display: block;
  }
  .app main {
    height: auto;
  }
  .app main h3 {
    font-size: 20px;
  }
  .app .standfirst {
    padding-left: 15px;
  }
  .app blockquote {
    font-size: 14px;
  }
}

.app .grid {
  display: -ms-grid;
  display: grid;
  grid-gap: 15px;
  height: 100%;
  padding: 15px;
  background-color: #f1f0f1;
}

.app .grid > :nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: child1;
}

.app .grid > :nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: child2;
}

.app .grid > :nth-child(3) {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: child3;
}

.app .grid > :nth-child(4) {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  grid-area: child4;
}

.app .grid > :nth-child(5) {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: child5;
}

.app .grid > :nth-child(6) {
  -ms-grid-row: 3;
  -ms-grid-column: 2;
  grid-area: child6;
}

.app .grid .card {
  min-height: 275px;
}

.app .grid.grid-1 {
      grid-template-areas: "child1";
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
}

.app .grid.grid-1 .card {
  min-height: 1px;
}

.app .grid.grid-2 {
      grid-template-areas: "child1" "child2";
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
}

.app .grid.grid-2 .card {
  max-height: 600px;
}

.app .grid.grid-3 {
      grid-template-areas: "child1" "child2" "child3";
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
}

.app .grid.grid-3 .card {
  max-height: 600px;
}

.app .grid.grid-4 {
      grid-template-areas: "child1 child2" "child3 child4";
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
}

.app .grid.grid-4 .card {
  max-height: 600px;
}

.app .grid.grid-5 {
      grid-template-areas: "child1 child2" "child3 child3" "child4 child5";
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
}

.app .grid.grid-5 .card {
  max-height: 600px;
}

.app .grid.grid-6 {
      grid-template-areas: "child1 child2" "child3 child4" "child5 child6";
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
}

.app .grid.grid-6 .card {
  max-height: 600px;
}

@media screen and (max-width: 1292px) {
  .app .grid.grid-3 {
        grid-template-areas: "child1" "child2" "child3";
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: auto;
        grid-template-rows: auto;
  }
  .app .grid.grid-4 {
        grid-template-areas: "child1" "child2" "child3" "child4";
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: auto;
        grid-template-rows: auto;
  }
  .app .grid.grid-5 {
        grid-template-areas: "child1" "child2" "child3" "child4" "child5";
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: auto;
        grid-template-rows: auto;
  }
  .app .grid.grid-6 {
        grid-template-areas: "child1" "child2" "child3" "child4" "child5" "child6";
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: auto;
        grid-template-rows: auto;
  }
}

@media screen and (max-width: 576px), screen and (max-height: 576px) {
  .app .grid.grid-2 .card,
  .app .grid.grid-3 .card,
  .app .grid.grid-5 .card,
  .app .grid.grid-6 .card {
    max-height: 50vh;
  }
}

@media screen and (max-width: 576px), screen and (max-height: 576px) and (max-width: 992px) {
  .app .grid {
    display: block;
    padding: 0;
    min-height: 100%;
    background-color: white;
  }
  .app .grid.grid-1 {
    display: -ms-grid;
    display: grid;
  }
  .app .grid.grid-2 .card,
  .app .grid.grid-3 .card,
  .app .grid.grid-4 .card,
  .app .grid.grid-5 .card,
  .app .grid.grid-6 .card {
    min-height: 150px;
  }
}

@media screen and (max-width: 448px), screen and (max-height: 448px) {
  .app .grid.grid-2 .card,
  .app .grid.grid-3 .card,
  .app .grid.grid-4 .card,
  .app .grid.grid-5 .card,
  .app .grid.grid-6 .card {
    max-height: 90vh;
  }
}

.app .card {
  height: 100%;
  border: 2px solid #cecece;
  border-radius: 10px;
  font-size: 18px;
  overflow: hidden;
}

.app .card.unscrollable {
  max-height: none !important;
}

.app .card.unscrollable .card-body {
  overflow: auto;
}

.app .card .card-header {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 8px 25px 8px 15px;
  border-bottom: 2px solid #cecece;
  border-radius: 10px 10px 0 0;
  background-color: white;
}

.app .card .card-header.composed-card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.app .card .card-header.composed-card-header h2 {
  margin-right: 15px;
}

.app .card .card-header.composed-card-header em {
  margin-top: 15px;
}

.app .card .card-header.card-header-with-back {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.app .card .card-header.card-header-with-back h2 {
  margin-right: 15px;
}

.app .card .card-header.card-header-with-back em {
  margin: 15px 10px 0 15px;
}

@media screen and (max-width: 576px) {
  .app .card .card-header.card-header-with-back em,
  .app .card .card-header.card-header-with-back .item-select-disable {
    display: none;
  }
}

.app .card .card-header h1,
.app .card .card-header h2 {
  line-height: 1;
  margin: 0;
  border-left: 5px solid #336699;
  font-size: 30px;
  color: #606060;
  text-transform: uppercase;
  font-weight: 500;
}

.app .card .card-header h1 {
  padding: 10px 0 5px 15px;
}

.app .card .card-header h2 {
  padding: 10px 0 2px 15px;
}

.app .card .card-row {
  background-color: rgba(51, 102, 153, 0.2);
  border-bottom: 2px solid #cecece;
  padding: 15px 50px;
}

.app .card .card-body {
  padding: 15px 25px 0;
  border-radius: 0 0 10px 10px;
  overflow: auto;
  overflow: hidden auto;
  scrollbar-color: #cecece white;
  scrollbar-width: thin;
}

.app .card .card-body::-webkit-scrollbar {
  background-color: white;
  height: 12px;
  width: 12px;
  border-radius: 6px;
}

.app .card .card-body::-webkit-scrollbar-thumb {
  background-color: #cecece;
  border-radius: 6px;
}

.app .card .card-body.table-wrapper {
  overflow: auto;
}

.app .card .card-body.table-wrapper .btn-wrapper {
  padding-top: 20px;
}

.app .card .card-body > :last-child {
  padding-bottom: 15px;
}

.app .card .card-body > .table:last-child {
  padding-bottom: 0;
  margin-bottom: 15px;
}

.app .card .card-footer {
  border-top: 2px solid #cecece;
  background-color: white;
  padding: 8px 15px;
  font-size: 12px;
}

.app .card .card-footer a {
  color: #606060;
  text-decoration: underline;
  margin-right: 15px;
}

@media screen and (max-width: 1200px) {
  .app .card .card-body {
    padding: 15px 15px 0;
  }
}

@media screen and (max-width: 576px), screen and (max-height: 576px) and (max-width: 992px) {
  .app .card {
    height: auto;
    min-height: 1px;
    border: 0;
    border-radius: 0;
    font-size: 14px;
    overflow: auto;
    border-top: 2px solid #cecece;
    padding-top: 10px;
  }
  .app .card:first-child {
    border-top: 0;
  }
  .app .card:last-child {
    margin-bottom: 0;
  }
  .app .card .card-row {
    padding: 15px 25px;
  }
  .app .card .card-header {
    border-bottom: 2px solid #f1f0f1;
    border-radius: 0;
  }
  .app .card .card-header h1,
  .app .card .card-header h2 {
    font-size: 24px;
  }
  .app .card .card-body {
    border-radius: 0;
    padding: 15px;
    overflow: auto;
  }
  .app .card .card-body::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }
  .app .card .card-body::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }
  .app .card .card-body.table-wrapper {
    padding: 15px 0 0;
  }
  .app .card .card-body.table-wrapper p {
    padding: 0 15px;
  }
  .app .card .card-body.table-wrapper .btn-wrapper {
    padding-bottom: 15px;
  }
  .app .card .card-body > :last-child {
    padding-bottom: 0;
  }
  .app .card > :last-child {
    margin-bottom: 0;
  }
  .app .card > :last-child .card-body {
    padding-bottom: 0;
  }
}

.app header {
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #336699;
  color: white;
  font-size: 23px;
  white-space: nowrap;
}

.app header a {
  height: 100%;
  padding: 20px 40px 15px;
  color: white;
}

.app header a:hover, .app header a:focus, .app header a:active, .app header a.active {
  color: #132639;
}

.app header .menu-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  height: 100%;
  max-width: 100%;
}

.app header .menu-top a {
  display: inline-block;
  text-decoration: none;
  color: white;
}

.app header .menu-top a:hover, .app header .menu-top a:focus, .app header .menu-top a:active, .app header .menu-top a.active {
  background-color: white;
  color: #336699;
  text-decoration: none;
}

.app header .menu-top .user-menu {
  position: relative;
  max-width: 300px;
  min-width: 0;
  z-index: 100;
}

.app header .menu-top .user-menu .user-menu-title {
  display: inline-block;
  position: relative;
  height: 100%;
  z-index: 1;
  background-color: #336699;
  color: white;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  min-width: 200px;
  text-align: center;
}

.app header .menu-top .user-menu .user-menu-title:hover, .app header .menu-top .user-menu .user-menu-title:focus, .app header .menu-top .user-menu .user-menu-title:active {
  background-color: white;
  color: #336699;
}

.app header .menu-top .user-menu .user-menu-hover {
  position: absolute;
  right: 0;
  left: 0;
  top: 100%;
  height: 0;
  width: 0;
  z-index: -1;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
  -webkit-transform: translateY(-101%);
          transform: translateY(-101%);
  font-size: 18px;
  font-weight: 500;
  overflow: hidden;
}

.app header .menu-top .user-menu .user-menu-hover a {
  color: #336699;
  padding: 20px 10px 10px;
  display: block;
  text-align: center;
}

.app header .menu-top .user-menu .user-menu-hover a:hover, .app header .menu-top .user-menu .user-menu-hover a:focus, .app header .menu-top .user-menu .user-menu-hover a:active, .app header .menu-top .user-menu .user-menu-hover a.active {
  background-color: #336699;
  color: white;
}

.app header .menu-top .user-menu .user-menu-hover ul {
  margin: 0;
  padding: 0;
}

.app header .menu-top .user-menu .user-menu-hover ul li {
  list-style-type: none;
}

.app header .menu-top .user-menu:hover .user-menu-title, .app header .menu-top .user-menu:focus .user-menu-title, .app header .menu-top .user-menu:active .user-menu-title, .app header .menu-top .user-menu.active .user-menu-title {
  background-color: white;
  color: #336699;
}

.app header .menu-top .user-menu:hover .user-menu-hover, .app header .menu-top .user-menu:focus .user-menu-hover, .app header .menu-top .user-menu:active .user-menu-hover, .app header .menu-top .user-menu.active .user-menu-hover {
  display: block;
  opacity: 1;
  background-color: white;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  white-space: normal;
  height: auto;
  width: auto;
}

.app header .property-link {
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  color: white;
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app header .property-link a {
  display: inline-block;
  text-decoration: none;
}

.app header .property-link a:hover, .app header .property-link a:focus, .app header .property-link a:active {
  background-color: white;
  color: #336699;
}

.app header .header-text {
  display: inline-block;
  padding: 20px 40px 15px;
  margin: 0;
  font-style: italic;
}

.app header .header-text a {
  text-decoration: underline;
  padding: 0;
}

@media screen and (max-width: 1200px) {
  .app header .header-text {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .app header {
    padding-left: 70px;
  }
  .app header a {
    padding-left: 20px;
    padding-right: 20px;
  }
  .app header .menu-top > a {
    display: none;
  }
  .app header .menu-top .user-menu .user-menu-title {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 576px) {
  .app header .property-link {
    display: none;
  }
}

.app aside {
  width: 300px;
  min-width: 300px;
  color: white;
  font-size: 25px;
}

.app aside .menu-burger {
  display: none;
}

.app aside .aside-content-wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: auto;
  overflow: hidden auto;
  padding-top: 70px;
  background-color: #336699;
  color: white;
  position: fixed;
  width: 300px;
  min-width: 300px;
}

.app aside .aside-content-wrapper .profile-img-wrapper {
  padding: 0 15px;
}

.app aside .aside-content-wrapper .profile-img-wrapper .profile-img {
  width: 165px;
  height: 165px;
  margin-bottom: 50px;
  border-radius: 50%;
}

.app aside .aside-content-wrapper .profile-img-wrapper div.profile-img {
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.app aside .aside-content-wrapper nav {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-height: 1px;
  width: 100%;
  margin-bottom: 50px;
}

.app aside .aside-content-wrapper nav ul {
  margin: 0;
  padding: 0;
}

.app aside .aside-content-wrapper nav ul li {
  list-style-type: none;
}

.app aside .aside-content-wrapper nav a {
  color: white;
  position: relative;
  padding: 0 50px;
  display: block;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
  z-index: 1;
  color: white;
}

.app aside .aside-content-wrapper nav a:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transform: translateX(-101%);
          transform: translateX(-101%);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.app aside .aside-content-wrapper nav a:hover,
.app aside .aside-content-wrapper nav a:focus,
.app aside .aside-content-wrapper nav a:active,
.app aside .aside-content-wrapper nav a.active {
  text-decoration: none;
  color: #336699;
  background-color: rgba(51, 102, 153, 0.3);
}

.app aside .aside-content-wrapper nav a:hover:before,
.app aside .aside-content-wrapper nav a:focus:before,
.app aside .aside-content-wrapper nav a:active:before,
.app aside .aside-content-wrapper nav a.active:before {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.app aside .aside-content-wrapper nav .contact-link {
  display: none;
}

.app aside .aside-content-wrapper nav .my-property-link {
  position: relative;
  margin-top: 10px;
  padding-top: 10px;
}

.app aside .aside-content-wrapper nav .my-property-link:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 30px;
  right: 30px;
  height: 2px;
  background-color: white;
}

.app aside .aside-content-wrapper .aside-bottom {
  padding: 0 40px 20px;
}

.app aside .aside-content-wrapper .aside-bottom .img-fluid {
  display: block;
  margin: 0 auto;
}

.app aside .aside-content-wrapper .aside-bottom .aside-playstore-img {
  display: none;
}

.app aside .aside-content-wrapper .aside-bottom .aside-tutorial-img {
  width: 55px;
  margin-bottom: 50px;
}

.app aside .aside-content-wrapper .aside-bottom .aside-application-logo {
  width: 110px;
}

.app aside .aside-content-wrapper .aside-bottom .aside-application-logo.img-grey {
  width: 100px;
}

@media screen and (max-width: 992px) {
  .app aside {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 998;
    -webkit-transform: translateX(-300px);
            transform: translateX(-300px);
  }
  .app aside:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100vh;
    background-color: rgba(96, 96, 96, 0.5);
    z-index: -1;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .app aside .menu-burger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 300px;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 10px;
    height: 70px;
    width: 70px;
    opacity: 1;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .app aside .menu-burger .menu-burger-slice {
    display: block;
    position: relative;
    background-color: white;
    height: 4px;
    width: 43px;
    border-radius: 2px;
    opacity: 1;
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  .app aside.in {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.3);
  }
  .app aside.in:before {
    width: 100vw;
    opacity: 1;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
  .app aside.in .menu-burger {
    left: calc(300px - 70px);
  }
  .app aside.in .menu-burger .menu-burger-slice:nth-child(1) {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 2px;
    left: 7px;
  }
  .app aside.in .menu-burger .menu-burger-slice:nth-child(2) {
    width: 0;
    opacity: 0;
  }
  .app aside.in .menu-burger .menu-burger-slice:nth-child(3) {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    bottom: 1px;
    left: 7px;
  }
  .app aside.in .aside-content-wrapper {
    overflow: auto;
    overflow: hidden auto;
    scrollbar-color: rgba(255, 255, 255, 0.9) #16792e;
    scrollbar-width: thin;
  }
  .app aside.in .aside-content-wrapper::-webkit-scrollbar {
    background-color: #16792e;
    width: 7px;
  }
  .app aside.in .aside-content-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
  }
  .app aside .aside-content-wrapper {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    padding-top: 50px;
    position: static;
  }
  .app aside .aside-content-wrapper nav .contact-link {
    display: block;
  }
  .app aside .aside-content-wrapper .aside-bottom {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }
  .app aside .aside-content-wrapper .aside-bottom .aside-playstore-img {
    display: block;
    width: 55px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 576px), screen and (max-height: 880px) {
  .app aside {
    font-size: 18px;
    width: 200px;
    min-width: 200px;
  }
  .app aside.in .menu-burger {
    left: calc(200px - 70px);
  }
  .app aside .aside-content-wrapper {
    width: 200px;
    min-width: 200px;
  }
  .app aside .aside-content-wrapper .profile-img-wrapper .profile-img {
    width: 100px;
    height: 100px;
    margin-bottom: 25px;
  }
  .app aside .aside-content-wrapper nav {
    margin-bottom: 25px;
  }
  .app aside .aside-content-wrapper nav a {
    padding: 0 25px;
  }
  .app aside .aside-content-wrapper .aside-bottom .aside-playstore-img {
    width: 40px;
    margin-bottom: 15px;
  }
  .app aside .aside-content-wrapper .aside-bottom .aside-tutorial-img {
    width: 40px;
    margin-bottom: 25px;
  }
}

@media screen and (max-height: 575px) {
  .app aside .aside-content-wrapper {
    position: static;
    width: 100%;
    min-width: 1px;
  }
  .app aside .aside-content-wrapper nav {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
  }
}

.app .form {
  font-size: 22px;
  width: 750px;
  max-width: 100%;
}

.app .form .form-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}

.app .form label {
  display: inline-block;
  font-weight: 500;
  padding-right: 15px;
  min-width: 0;
}

.app .form input::-webkit-input-placeholder {
  color: #cecece;
  opacity: 1;
}

.app .form input:-ms-input-placeholder {
  color: #cecece;
  opacity: 1;
}

.app .form input::-ms-input-placeholder {
  color: #cecece;
  opacity: 1;
}

.app .form input::placeholder {
  color: #cecece;
  opacity: 1;
}

.app .form .form-item-list input,
.app .form .form-item-list textarea {
  display: inline-block;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-width: 0;
  border: 2px solid #cecece;
  border-radius: 13px;
  line-height: 1;
  color: #606060;
  padding: 0 10px 0;
}

.app .form .form-item-list textarea {
  height: 150px;
}

.app .form .form-item-list .form-item-check-box {
  display: none;
}

.app .form .form-item-check-box-label {
  padding-left: 40px;
  position: relative;
  margin: 0 auto;
}

.app .form .form-item-check-box-label:before, .app .form .form-item-check-box-label:after {
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.app .form .form-item-check-box-label:before {
  content: "";
  left: 0;
  height: 30px;
  width: 30px;
  border: 2px solid #606060;
}

.app .form .form-item-check-box-label:after {
  content: url("images/pictogrammes/checked-grey.svg");
  left: 3px;
  height: 24px;
  width: 24px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.app .form .form-item-check-box-label.checked:after {
  opacity: 1;
}

.app .form .form-item-image-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.app .form .form-item-image-wrapper .fake-label {
  display: inline-block;
}

.app .form .form-item-image-wrapper .form-item-image .form-group {
  margin: 0;
}

.app .form .form-item-image-wrapper .form-item-image label.btn, .app .form .form-item-image-wrapper .form-item-image .pagination label.pager, .app .pagination .form .form-item-image-wrapper .form-item-image label.pager {
  margin-top: 10px;
  padding-right: 35px;
  width: auto;
}

.app .form .form-item-image-wrapper .form-item-image label.btn.checked:after, .app .form .form-item-image-wrapper .form-item-image .pagination label.checked.pager:after, .app .pagination .form .form-item-image-wrapper .form-item-image label.checked.pager:after {
  background-image: url("images/pictogrammes/checked-white.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: auto 16px;
}

.app .form .form-item-image-wrapper .form-item-image .input-image {
  display: none;
}

.app .submit-button-wrapper {
  text-align: center;
  padding-top: 70px;
}

@media screen and (max-height: 768px) {
  .app .submit-button-wrapper {
    padding-top: 25px;
  }
}

@media screen and (max-width: 576px), screen and (max-height: 448px) and (max-width: 992px) {
  .app .form {
    font-size: 18px;
  }
  .app .form .form-item {
    display: block;
  }
  .app .form .form-item input,
  .app .form .form-item textarea {
    display: block;
    width: 100%;
    padding: 5px 10px;
  }
}

.app .table {
  text-align: center;
  margin-bottom: 0;
}

.app .table tbody {
  border: 0;
}

.app .table thead tr {
  background-color: rgba(51, 102, 153, 0.3);
  color: #336699;
}

.app .table .tr-colored {
  background-color: rgba(51, 102, 153, 0.2);
}

.app .table th {
  vertical-align: middle;
  padding: 10px;
  font-weight: 600;
  border: 0;
  text-transform: uppercase;
}

.app .table td {
  padding: 10px;
  border: 0;
  vertical-align: middle;
}

.app .table .td-align-left, .app .table .td-label {
  text-align: left !important;
}

.app .table .td-align-right {
  text-align: right !important;
}

.app .table .td-label {
  word-break: break-word;
  min-width: 175px;
}

@media screen and (max-width: 576px) {
  .app .table .td-label {
    min-width: 145px;
  }
}

.app .table .title,
.app .table .subtitle,
.app .table .total,
.app .table .subtotal {
  color: #336699;
  font-weight: 600;
}

.app .table .subtotal,
.app .table .subsubtotal {
  color: #336699;
  background-color: rgba(206, 206, 206, 0.6);
}

.app .table .subsubtotal {
  font-weight: 500;
}

.app .table .highlight {
  font-weight: 500;
}

.app .table .amount {
  white-space: nowrap;
  text-align: right;
}

.app .table .date {
  white-space: nowrap;
}

.app .table .amount-neutral {
  color: #606060;
}

.app .table .amount-negative {
  color: #c73636;
}

.app .table .amount-positive {
  color: #1c9739;
}

.app .table .picto-table-wrapper {
  display: inline-block;
}

.app .table .table-picto {
  height: 25px;
}

.app .table .table-picto:hover, .app .table .table-picto:focus, .app .table .table-picto:active {
  cursor: pointer;
}

.app .table a.table-picto {
  display: inline-block;
  text-indent: -9999px;
  width: 40px;
}

.app .table .table-hidden-rows tr {
  background-color: white;
}

.app .table .table-hidden-rows tr td {
  padding: 10px;
}

.app .table .table-hidden-rows tr .animated-cell {
  -webkit-transition: max-height 0.5s;
  transition: max-height 0.5s;
  overflow: hidden;
  max-height: 100px;
  text-overflow: ellipsis;
}

.app .table .table-hidden-rows .tr-clickable {
  cursor: pointer;
}

.app .table .table-hidden-rows .hideable {
  border-top: 1px solid white;
}

.app .table .table-hidden-rows .hideable td {
  background-color: #cecece;
}

.app .table .table-hidden-rows .hidden {
  border-top: 0;
  overflow: hidden;
}

.app .table .table-hidden-rows .hidden:after {
  content: none;
}

.app .table .table-hidden-rows .hidden td {
  padding-top: 0;
  padding-bottom: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.app .table .table-hidden-rows .hidden td .animated-cell {
  max-height: 0;
}

.app .table.horizontal {
  padding-bottom: 0 !important;
}

.app .table.horizontal th,
.app .table.horizontal td {
  word-break: normal;
  padding: 10px 15px;
  line-height: inherit;
}

.app .table.horizontal th {
  text-align: center;
  background-color: rgba(51, 102, 153, 0.3);
  color: #336699;
}

.app .table.horizontal td {
  text-align: left;
  width: 100%;
}

.app .table.horizontal tr:nth-child(odd) td {
  background-color: rgba(51, 102, 153, 0.2);
}

.app .table.horizontal tbody.reverse-color tr:nth-child(odd) td {
  background-color: white;
}

.app .table.horizontal tbody.reverse-color tr:nth-child(even) td {
  background-color: rgba(51, 102, 153, 0.2);
}

@media screen and (max-width: 448px), screen and (max-height: 448px) and (max-width: 992px) {
  .app .table th,
  .app .table td,
  .app .table .table-hidden-rows tr td {
    padding: 5px;
  }
  .app .table.horizontal td {
    width: auto;
  }
}

.app .overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(96, 96, 96, 0.5);
  z-index: 999;
  padding: 20px;
}

.app .overlay .modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 800px;
  max-width: 100%;
  max-height: 100vh;
  min-width: 0;
  border: 4px solid #336699;
  border-radius: 10px;
  background-color: white;
}

.app .overlay .modal .modal-scrollbar {
  overflow: auto;
  scrollbar-color: #cecece white;
  scrollbar-width: thin;
  max-height: 100%;
  padding: 40px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.app .overlay .modal .modal-scrollbar::-webkit-scrollbar {
  background-color: white;
  height: 12px;
  width: 12px;
}

.app .overlay .modal .modal-scrollbar::-webkit-scrollbar-thumb {
  background-color: #cecece;
}

.app .overlay .modal .modal-scrollbar .inner-modal {
  margin: 0 auto;
}

.app .overlay .modal .modal-scrollbar .inner-modal .close-modal {
  position: absolute;
  top: 7px;
  left: 7px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  z-index: 10;
}

.app .overlay .modal .modal-scrollbar .inner-modal .close-modal img {
  width: 20px;
  height: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.app .overlay .modal .modal-scrollbar .inner-modal .close-modal:hover img, .app .overlay .modal .modal-scrollbar .inner-modal .close-modal:focus img, .app .overlay .modal .modal-scrollbar .inner-modal .close-modal:active img {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.app .overlay .modal .modal-scrollbar .inner-modal .modal-header {
  padding: 0;
}

.app .overlay .modal .modal-scrollbar .inner-modal .modal-header h2 {
  line-height: 1;
  margin: 0 0 20px;
  border-left: 5px solid #336699;
  font-size: 30px;
  color: #606060;
  text-transform: uppercase;
  font-weight: 500;
  padding: 10px 0 2px 15px;
}

@media screen and (max-width: 576px) {
  .app .overlay {
    padding: 0;
  }
  .app .overlay .modal {
    border: 0;
    border-radius: 0;
  }
  .app .overlay .modal .modal-scrollbar {
    padding: 15px;
  }
}

.app .card .card-body.card-graph {
  padding-left: 0;
  padding-right: 0;
}

.graph {
  margin: 0 auto;
}

.graph * {
  font-family: "futura", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.graph.graph-col {
  max-width: 100%;
  margin-bottom: 15px;
  height: 300px;
}

.graph.graph-col.graph-1-col {
  width: 200px;
  min-width: 200px;
}

.graph.graph-col.graph-2-col {
  width: 400px;
  min-width: 400px;
}

.graph.graph-col.graph-3-col {
  width: 600px;
  min-width: 600px;
}

.graph.graph-circle {
  max-width: 100%;
  height: auto;
  width: 250px;
}

.highcharts-tooltip > span {
  background-color: white;
  border: 1px solid #cecece;
  padding: 5px;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 1;
  font-size: 14px !important;
}

.highcharts-tooltip > span ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.highcharts-tooltip > span ul li:first-child:not(:last-child) {
  margin-bottom: 5px;
}

.highcharts-tooltip > span ul li {
  position: relative;
  padding-left: 10px;
}

.highcharts-tooltip > span ul li:before {
  content: '';
  position: absolute;
  left: 0;
  top: calc(50% - 2px);
  display: block;
  width: 5px;
  height: 5px;
  background-color: #336699;
  border-radius: 50%;
}

@media screen and (max-width: 576px) {
  .graph.graph-col {
    height: 200px;
  }
  .graph.graph-col.graph-1-col {
    width: 150px;
    min-width: 150px;
  }
  .graph.graph-col.graph-2-col {
    width: 300px;
    min-width: 300px;
  }
  .graph.graph-col.graph-3-col {
    width: 450px;
    min-width: 450px;
  }
  .graph.graph-col .highcharts-label text {
    font-size: 14px !important;
  }
  .graph.graph-circle {
    max-width: 100%;
    height: auto;
    width: 150px;
  }
  .graph.graph-circle .progressbar-text {
    font-size: 18px !important;
  }
}

@media screen and (max-height: 448px) and (max-width: 992px) {
  .graph.graph-col {
    height: 200px;
  }
  .graph.graph-col .highcharts-label text {
    font-size: 14px !important;
  }
  .graph.graph-circle {
    max-width: 100%;
    height: auto;
    width: 150px;
  }
  .graph.graph-circle .progressbar-text {
    font-size: 18px !important;
  }
}

.app .btn, .app .pagination .pager {
  position: relative;
  padding: 3px 35px 0;
  border: 0;
  border-radius: 10px;
  background-color: transparent;
  color: white;
  white-space: normal;
  font-size: 19px;
  font-weight: 500;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
  z-index: 1;
}

.app .btn:before, .app .pagination .pager:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #204060;
  -webkit-transform: translateX(-101%);
          transform: translateX(-101%);
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  z-index: -1;
}

.app .btn:after, .app .pagination .pager:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #336699;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -1;
}

.app .btn:hover, .app .pagination .pager:hover, .app .btn:focus, .app .pagination .pager:focus, .app .btn:active, .app .pagination .pager:active, .app .btn.active, .app .pagination .active.pager {
  background-color: transparent;
  color: white;
}

.app .btn:hover:before, .app .pagination .pager:hover:before, .app .btn:focus:before, .app .pagination .pager:focus:before, .app .btn:active:before, .app .pagination .pager:active:before, .app .btn.active:before, .app .pagination .active.pager:before {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.app .btn:hover:after, .app .pagination .pager:hover:after, .app .btn:focus:after, .app .pagination .pager:focus:after, .app .btn:active:after, .app .pagination .pager:active:after, .app .btn.active:after, .app .pagination .active.pager:after {
  -webkit-transform: translateX(101%);
          transform: translateX(101%);
}

.app .btn.active, .app .pagination .active.pager {
  z-index: 2;
}

.app .btn.light-btn, .app .pagination .light-btn.pager {
  color: #336699;
  background-color: white;
}

.app .btn.light-btn:hover, .app .pagination .light-btn.pager:hover, .app .btn.light-btn:focus, .app .pagination .light-btn.pager:focus, .app .btn.light-btn:active, .app .pagination .light-btn.pager:active, .app .btn.light-btn.active, .app .pagination .light-btn.active.pager {
  color: white;
  background-color: transparent;
}

.app .btn.light-btn:after, .app .pagination .light-btn.pager:after {
  background-color: white;
}

.app .btn.light-btn:before, .app .pagination .light-btn.pager:before {
  background-color: #336699;
}

.app .btn.grey, .app .pagination .grey.pager {
  color: white;
}

.app .btn.grey:after, .app .pagination .grey.pager:after {
  background-color: #606060;
}

.app .btn.grey:hover, .app .pagination .grey.pager:hover, .app .btn.grey:focus, .app .pagination .grey.pager:focus, .app .btn.grey:active, .app .pagination .grey.pager:active, .app .btn.grey.active, .app .pagination .grey.active.pager {
  color: #606060;
}

.app .btn.grey:before, .app .pagination .grey.pager:before {
  background-color: #cecece;
}

.app .btn.btn-orange, .app .pagination .btn-orange.pager {
  background-color: #ff9913;
}

.app .btn.btn-orange:after, .app .pagination .btn-orange.pager:after {
  background-color: #ff9913;
}

.app .btn.btn-orange:before, .app .pagination .btn-orange.pager:before {
  background-color: #c67000;
}

.app .btn.btn-large:before, .app .pagination .btn-large.pager:before {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: all 0.45s;
  transition: all 0.45s;
}

.app .btn.btn-large:after, .app .pagination .btn-large.pager:after {
  -webkit-transition: all 0.45s;
  transition: all 0.45s;
}

.app .btn.btn-large:hover:before, .app .pagination .btn-large.pager:hover:before, .app .btn.btn-large:focus:before, .app .pagination .btn-large.pager:focus:before, .app .btn.btn-large:active:before, .app .pagination .btn-large.pager:active:before, .app .btn.btn-large.active:before, .app .pagination .btn-large.active.pager:before {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.app .btn.btn-large:hover:after, .app .pagination .btn-large.pager:hover:after, .app .btn.btn-large:focus:after, .app .pagination .btn-large.pager:focus:after, .app .btn.btn-large:active:after, .app .pagination .btn-large.pager:active:after, .app .btn.btn-large.active:after, .app .pagination .btn-large.active.pager:after {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.app .btn.btn-image, .app .pagination .btn-image.pager {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 25px;
}

.app .btn.btn-image img, .app .pagination .btn-image.pager img {
  display: inline;
  height: 17px;
  width: auto;
  margin-right: 15px;
}

.back-btn {
  position: relative;
  background: url("images/pictogrammes/back-arrow-grey.svg") no-repeat left center transparent;
  background-size: 20px;
  border: 0;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  width: 20px;
  height: 20px;
  text-indent: -9999px;
  margin-right: 20px;
}

.back-btn:before {
  content: '';
  background: url("images/pictogrammes/back-arrow-yellow.svg") no-repeat left center transparent;
  background-size: 20px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.back-btn:hover:before, .back-btn:focus:before, .back-btn:active:before {
  width: 20px;
}

.btn-print {
  display: block;
  background-color: transparent;
  border: 0;
  width: 45px;
  margin-left: auto;
  cursor: pointer;
  position: relative;
  padding: 0;
}

.btn-print:before {
  content: '';
  position: absolute;
  background-color: white;
  height: 4px;
  top: 45px;
  left: 0;
  right: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-print:hover:before, .btn-print:focus:before, .btn-print:active:before {
  bottom: auto;
  top: -4px;
}

.btn-print img {
  max-width: 100%;
  height: auto;
}

.btn-wrapper {
  text-align: center;
}

.toggle-btn-wrapper {
  text-align: center;
  margin-bottom: 15px;
}

.toggle-btn-wrapper .btn, .toggle-btn-wrapper .app .pagination .pager, .app .pagination .toggle-btn-wrapper .pager {
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 0 35px 0 15px;
}

.toggle-btn-wrapper .btn img, .toggle-btn-wrapper .app .pagination .pager img, .app .pagination .toggle-btn-wrapper .pager img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}

.toggle-btn-wrapper .btn span, .toggle-btn-wrapper .app .pagination .pager span, .app .pagination .toggle-btn-wrapper .pager span {
  vertical-align: middle;
}

.toggle-btn-wrapper .btn.active, .toggle-btn-wrapper .app .pagination .active.pager, .app .pagination .toggle-btn-wrapper .active.pager {
  cursor: mouse;
}

.card-btn:hover .btn, .card-btn:hover .app .pagination .pager, .app .pagination .card-btn:hover .pager, .card-btn:focus .btn, .card-btn:focus .app .pagination .pager, .app .pagination .card-btn:focus .pager, .card-btn:active .btn, .card-btn:active .app .pagination .pager, .app .pagination .card-btn:active .pager {
  color: #606060;
  background-color: transparent;
}

.card-btn:hover .btn:before, .card-btn:hover .app .pagination .pager:before, .app .pagination .card-btn:hover .pager:before, .card-btn:focus .btn:before, .card-btn:focus .app .pagination .pager:before, .app .pagination .card-btn:focus .pager:before, .card-btn:active .btn:before, .card-btn:active .app .pagination .pager:before, .app .pagination .card-btn:active .pager:before {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.card-btn:hover .btn:after, .card-btn:hover .app .pagination .pager:after, .app .pagination .card-btn:hover .pager:after, .card-btn:focus .btn:after, .card-btn:focus .app .pagination .pager:after, .app .pagination .card-btn:focus .pager:after, .card-btn:active .btn:after, .card-btn:active .app .pagination .pager:after, .app .pagination .card-btn:active .pager:after {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.card-btn .card-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.app .property-teaser .btn, .app .property-teaser .pagination .pager, .app .pagination .property-teaser .pager {
  color: white;
  background-color: #ff9913;
}

.app .property-teaser .btn:after, .app .property-teaser .pagination .pager:after, .app .pagination .property-teaser .pager:after {
  background-color: #ff9913;
}

.app .property-teaser .btn:hover, .app .property-teaser .pagination .pager:hover, .app .pagination .property-teaser .pager:hover,
.app .property-teaser .btn:focus,
.app .property-teaser .pagination .pager:focus,
.app .pagination .property-teaser .pager:focus,
.app .property-teaser .btn:active,
.app .property-teaser .pagination .pager:active,
.app .pagination .property-teaser .pager:active,
.app .property-teaser .btn.active,
.app .property-teaser .pagination .active.pager,
.app .pagination .property-teaser .active.pager {
  color: #ff9913;
}

.app .property-teaser .btn:before, .app .property-teaser .pagination .pager:before, .app .pagination .property-teaser .pager:before {
  background-color: white;
}

.app .property-teaser .btn.light-btn, .app .property-teaser .pagination .light-btn.pager, .app .pagination .property-teaser .light-btn.pager {
  color: #ff9913;
  background-color: white;
}

.app .property-teaser .btn.light-btn:hover, .app .property-teaser .pagination .light-btn.pager:hover, .app .pagination .property-teaser .light-btn.pager:hover,
.app .property-teaser .btn.light-btn:focus,
.app .property-teaser .pagination .light-btn.pager:focus,
.app .pagination .property-teaser .light-btn.pager:focus,
.app .property-teaser .btn.light-btn:active,
.app .property-teaser .pagination .light-btn.pager:active,
.app .pagination .property-teaser .light-btn.pager:active,
.app .property-teaser .btn.light-btn.active,
.app .property-teaser .pagination .light-btn.active.pager,
.app .pagination .property-teaser .light-btn.active.pager {
  color: white;
}

.app .property-teaser .btn.light-btn:before, .app .property-teaser .pagination .light-btn.pager:before, .app .pagination .property-teaser .light-btn.pager:before {
  background-color: #ff9913;
}

.app .property-teaser .btn.grey, .app .property-teaser .pagination .grey.pager, .app .pagination .property-teaser .grey.pager {
  color: white;
}

.app .property-teaser .btn.grey:after, .app .property-teaser .pagination .grey.pager:after, .app .pagination .property-teaser .grey.pager:after {
  background-color: #606060;
}

.app .property-teaser .btn.grey:hover, .app .property-teaser .pagination .grey.pager:hover, .app .pagination .property-teaser .grey.pager:hover, .app .property-teaser .btn.grey:focus, .app .property-teaser .pagination .grey.pager:focus, .app .pagination .property-teaser .grey.pager:focus, .app .property-teaser .btn.grey:active, .app .property-teaser .pagination .grey.pager:active, .app .pagination .property-teaser .grey.pager:active, .app .property-teaser .btn.grey.active, .app .property-teaser .pagination .grey.active.pager, .app .pagination .property-teaser .grey.active.pager {
  color: #606060;
}

.app .property-teaser .btn.grey:before, .app .property-teaser .pagination .grey.pager:before, .app .pagination .property-teaser .grey.pager:before {
  background-color: #cecece;
}

.app .property-teaser:hover .btn, .app .property-teaser:hover .pagination .pager, .app .pagination .property-teaser:hover .pager, .app .property-teaser:focus .btn, .app .property-teaser:focus .pagination .pager, .app .pagination .property-teaser:focus .pager, .app .property-teaser:active .btn, .app .property-teaser:active .pagination .pager, .app .pagination .property-teaser:active .pager {
  color: #ff9913;
}

.app .property-teaser:hover .btn:before, .app .property-teaser:hover .pagination .pager:before, .app .pagination .property-teaser:hover .pager:before, .app .property-teaser:focus .btn:before, .app .property-teaser:focus .pagination .pager:before, .app .pagination .property-teaser:focus .pager:before, .app .property-teaser:active .btn:before, .app .property-teaser:active .pagination .pager:before, .app .pagination .property-teaser:active .pager:before {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.app .property-teaser:hover .btn:after, .app .property-teaser:hover .pagination .pager:after, .app .pagination .property-teaser:hover .pager:after, .app .property-teaser:focus .btn:after, .app .property-teaser:focus .pagination .pager:after, .app .pagination .property-teaser:focus .pager:after, .app .property-teaser:active .btn:after, .app .property-teaser:active .pagination .pager:after, .app .pagination .property-teaser:active .pager:after {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.app .property-teaser:hover .btn.light-btn, .app .property-teaser:hover .pagination .light-btn.pager, .app .pagination .property-teaser:hover .light-btn.pager, .app .property-teaser:focus .btn.light-btn, .app .property-teaser:focus .pagination .light-btn.pager, .app .pagination .property-teaser:focus .light-btn.pager, .app .property-teaser:active .btn.light-btn, .app .property-teaser:active .pagination .light-btn.pager, .app .pagination .property-teaser:active .light-btn.pager {
  color: white;
}

.app .dropdown .btn, .app .dropdown .pagination .pager, .app .pagination .dropdown .pager {
  padding: 5px 10px;
}

.app .dropdown .dropdown-menu.right {
  right: 0;
  left: auto;
}

@media (max-width: 576px) {
  .app .dropdown .dropdown-menu {
    width: 90vw;
  }
  .app .dropdown .dropdown-menu .dropdown-item {
    white-space: normal;
  }
}

.app .pagination {
  margin: 0;
  padding: 15px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.app .pagination li {
  margin: 0 7px;
}

.app .pagination li:first-child {
  margin-left: 0;
}

.app .pagination li:last-child {
  margin-right: 0;
}

.app .pagination .pager {
  padding: 3px 10px 0;
  border: 2px solid #204060;
  border-radius: 3px;
  color: #204060;
}

.app .pagination .pager.active {
  color: white;
  border: 2px solid #132639;
}

.app .pagination .pager.active:before {
  background-color: #132639;
}

.app .pagination .pager:after {
  content: none;
}

.loading {
  display: block;
  position: relative;
  width: 38px;
  height: 38px;
  margin: 0 auto;
  padding-bottom: 15px;
}

.loading:before {
  background-color: red;
}

.loading div {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 4px;
  border: 4px solid #fff;
  border-radius: 50%;
  -webkit-animation: loading 1.2s cubic-bezier(0.2, 0.45, 0.7, 0.2) infinite;
          animation: loading 1.2s cubic-bezier(0.2, 0.45, 0.7, 0.2) infinite;
  border-color: #336699 transparent transparent transparent;
}

.loading div:first-child {
  -webkit-animation-delay: -0.05s;
          animation-delay: -0.05s;
  border: 4px solid rgba(51, 102, 153, 0.3);
}

.loading div:nth-child(2) {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

.loading div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.app.wrap-edge-message .page {
  padding-top: 48px;
}

.app.wrap-edge-message .edge-message {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 48px;
  background-color: #ffe7bc;
  padding: 0 15px;
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app.wrap-edge-message .edge-message p {
  margin: auto;
}

.app.wrap-edge-message .edge-message a {
  text-decoration: underline;
  color: #0056b3;
}

.app.wrap-edge-message .edge-message .close {
  height: 20px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  line-height: 0;
  vertical-align: middle;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.app.wrap-edge-message .edge-message .close img {
  width: 15px;
  height: 15px;
}

.app .grid.login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.app .grid.login .card {
  margin-top: 125px;
  height: auto;
  width: 800px;
  max-width: 100%;
  min-height: 200px;
}

.app .grid.login .card .card-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.app .grid.login .card .card-body p {
  font-size: 15px;
}

.app .grid.login .card .card-body .form {
  padding-top: 15px;
  min-width: 0;
}

.app .grid.login .card .card-body .form label {
  width: 170px;
}

.app .grid.login .card .card-body .form .submit-button-wrapper {
  padding-top: 30px;
  padding-bottom: 15px;
}

.app .grid.login .card .card-body .forgotten-password {
  -ms-flex-item-align: start;
      align-self: flex-start;
  padding-top: 15px;
}

@media screen and (max-height: 770px) {
  .app .grid.login {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .app .grid.login .card {
    margin-top: 0;
  }
}

@media screen and (max-height: 576px) and (max-width: 992px) {
  .app .grid.login {
    width: 100%;
  }
  .app .grid.login .card {
    height: 100vh;
    margin-top: 0;
    width: 100%;
  }
  .app .grid.login .card .card-body {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
}

@media screen and (max-height: 480px) {
  .app .grid.login .card .submit-button-wrapper {
    padding-top: 25px;
  }
}

@media screen and (max-height: 448px) and (max-width: 992px) {
  .app .grid.login .card .card-body .form {
    width: 325px;
  }
}

@media screen and (max-width: 576px) {
  .app .grid.login .card {
    height: 100vh;
    margin-top: 0;
  }
  .app .grid.login .card .card-body {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
  .app .grid.login .card .card-body .form {
    width: 325px;
    max-width: 100%;
  }
  .app .grid.login .card .card-body .form .form-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .app .grid.login .card .card-body .form .form-item input {
    line-height: inherit;
  }
}

.my-properties .property-list {
  padding-top: 30px 0 0 15px;
  background-color: transparent;
  -ms-grid-columns: (minmax(350px, 1fr))[auto-fill];
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  height: auto;
}

.my-properties .property-list .property-teaser {
  grid-area: inherit;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 25px 25px 15px 25px;
  border-radius: 10px;
  color: white;
  background-color: #458cd4;
}

.my-properties .property-list .property-teaser h2 {
  font-size: 22px;
  text-transform: uppercase;
}

.my-properties .property-list .property-teaser h2 a {
  color: white;
}

.my-properties .property-list .property-teaser h2 a:hover, .my-properties .property-list .property-teaser h2 a:focus, .my-properties .property-list .property-teaser h2 a:active {
  color: white;
  text-decoration: none;
}

.my-properties .property-list .property-teaser .property_nb_lot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  width: 100%;
}

.my-properties .property-list .property-teaser .property_nb_lot p {
  min-width: 0;
  margin-bottom: 5px;
}

.my-properties .property-list .property-teaser .property_nb_lot span {
  min-width: 0;
  margin-left: auto;
}

.my-properties .modal {
  font-size: 24px;
}

.my-properties .modal .modal-scrollbar {
  padding: 40px 40px 0;
}

.my-properties .modal .modal-scrollbar h2 {
  text-align: center;
  font-size: 26px;
  margin-bottom: 60px;
}

.my-properties .modal .modal-scrollbar p {
  text-align: center;
  margin-bottom: 60px;
}

.my-properties .modal .modal-scrollbar .form {
  font-size: 24px;
  max-width: 100%;
}

.my-properties .modal .modal-scrollbar .form .form-item {
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 40px;
}

.my-properties .modal .modal-scrollbar .form .submit-button-wrapper {
  padding-top: 0;
  padding-bottom: 15px;
}

@media screen and (max-width: 576px), screen and (max-height: 576px) {
  .my-properties .modal p {
    font-size: 16px;
  }
}

.app .my-info .card {
  font-size: 22px;
}

.app .my-info .card .btn-menu {
  display: inline-block;
  background-color: #cecece;
  border-radius: 10px;
  overflow: hidden;
  line-height: 1;
}

.app .my-info .card .btn-menu .btn:not(:first-child), .app .my-info .card .btn-menu .pagination .pager:not(:first-child), .app .pagination .my-info .card .btn-menu .pager:not(:first-child) {
  margin-left: -18px;
  padding-left: 45px;
}

.app .my-info .card .my-info-form {
  padding-bottom: 25px;
}

.app .my-info .card .my-info-form label {
  width: 140px;
}

.app .my-info .card .my-info-form .fake-label {
  font-weight: 500;
}

.app .my-info .card .my-info-form .cgu {
  margin-bottom: 20px;
  padding-top: 10px;
}

.app .my-info .card .my-info-form .cgu a {
  padding-right: 0;
}

.app .my-info .card .my-info-form .form-item-image-wrapper .fake-label {
  width: 140px;
}

.app .my-info .card .my-info-form .form-item-image-wrapper .form-item-image {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-width: 215px;
  border: 1px solid #cecece;
  border-radius: 5px;
  padding: 20px 15px;
  font-size: 16px;
}

.app .my-info .card .my-info-password .form-item-list .form-item label {
  width: 270px;
  margin-bottom: 0;
}

.app .my-info .card .my-info-password .form-item-list .form-item input {
  padding: 0 10px;
  height: 35px;
}

@media screen and (max-width: 448px) {
  .app .my-info .card .btn-menu {
    display: inline-block;
    border-radius: 0;
    background-color: transparent;
    margin-bottom: -10px;
  }
  .app .my-info .card .btn-menu .btn, .app .my-info .card .btn-menu .pagination .pager, .app .pagination .my-info .card .btn-menu .pager {
    margin-right: 15px;
    margin-bottom: 10px;
  }
  .app .my-info .card .btn-menu .btn:not(:first-child), .app .my-info .card .btn-menu .pagination .pager:not(:first-child), .app .pagination .my-info .card .btn-menu .pager:not(:first-child) {
    margin-left: 0;
    padding-left: 35px;
  }
  .app .my-info .card .my-info-form .table {
    margin-bottom: 25px;
  }
  .app .my-info .card .my-info-form .form-item-image-wrapper {
    display: block;
  }
  .app .my-info .card .my-info-form .form-item-image-wrapper .fake-label {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 576px), screen and (max-height: 448px) and (max-width: 992px) {
  .app .my-info .card {
    font-size: 14px;
  }
  .app .my-info .card .my-info-form .form-item-image-wrapper .fake-label {
    font-size: 18px;
  }
}

.app .dashboard.parent-graph.grid {
  -ms-grid-rows: 1fr auto;
      grid-template-rows: 1fr auto;
}

.app .dashboard .notification {
  position: relative;
  padding-left: 40px;
}

.app .dashboard .notification:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  height: 20px;
  width: 20px;
  background-color: #336699;
  border-radius: 50%;
}

.app .dashboard .notification h2 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
}

.app .dashboard .notification:last-child p {
  margin-bottom: 0;
}

@media screen and (max-width: 576px) {
  .app .dashboard .notification {
    padding-left: 30px;
  }
  .app .dashboard .notification:before {
    height: 15px;
    width: 15px;
  }
  .app .dashboard .notification h2 {
    font-size: 18px;
  }
}

@media screen and (max-height: 576px) {
  .app .dashboard .notification h2 {
    font-size: 18px;
  }
}

@media screen and (max-width: 448px) {
  .app .dashboard .notification {
    padding-left: 20px;
  }
  .app .dashboard .notification::before {
    left: -5px;
    width: 12px;
    height: 12px;
  }
}

.app .my-data.grid.grid-3 {
      grid-template-areas: "child1 child2" "child3 child3";
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr auto;
      grid-template-rows: 1fr auto;
}

.app .my-data.grid.grid-3 .table-lots .td-label {
  min-width: 50px;
}

.app .table-lease th {
  white-space: nowrap;
}

.app .table-lease tr.title td, .app .table-lease tr.title th {
  background-color: white;
}

.app .table-lease .file {
  display: inline-block;
}

.app .table-lease .file .picto-table {
  height: 33px;
  padding: 3px 0;
}

.app .table-leases tbody tr {
  cursor: pointer;
}

.app #lease-modal .modal {
  width: 1000px;
}

@media screen and (max-width: 1200px) {
  .app .my-data.grid.grid-3 {
        grid-template-areas: "child1" "child2" "child3";
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: auto;
        grid-template-rows: auto;
  }
}

@media screen and (max-width: 768px) {
  .app .table-lease th {
    white-space: normal;
  }
}

.app .incident .card-header .btn, .app .incident .card-header .pagination .pager, .app .pagination .incident .card-header .pager {
  margin-right: 20px;
}

.app .incident .table.table-incidents tbody .table-picto {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.app .incident .table.table-incidents tbody .table-picto.clicked {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.app .incident #incident-modal .modal {
  width: 1100px;
  max-width: 100%;
}

.app .incident #incident-modal .modal .modal-scrollbar {
  padding: 30px 30px;
}

.app .incident #incident-modal .modal .modal-scrollbar h2 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

.app .incident #incident-modal .modal .modal-scrollbar .form {
  margin: 0 auto;
}

.app .incident #incident-modal .modal .modal-scrollbar .form label,
.app .incident #incident-modal .modal .modal-scrollbar .form .fake-label {
  padding-right: 30px;
  width: 125px;
}

.app .incident #incident-modal .modal .modal-scrollbar .form label.btn, .app .incident #incident-modal .modal .modal-scrollbar .form .pagination label.pager, .app .pagination .incident #incident-modal .modal .modal-scrollbar .form label.pager {
  padding-right: 35px;
  width: auto;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .fake-label {
  display: inline-block;
  min-width: 125px;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .form-item-image-incident-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .form-item-image-wrapper .fake-label {
  width: 140px;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .form-item-image-wrapper .form-item-image {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-width: 300px;
  margin-right: 15px;
  background-color: #cecece;
  border-radius: 15px;
  padding: 10px 15px;
  font-size: 16px;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .form-item-image-wrapper .form-item-image h3 {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .form-item-image-wrapper .form-item-image .form-group {
  text-align: center;
  margin-bottom: 0;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .incident-form-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  min-width: 0;
  font-size: 18px;
  vertical-align: middle;
  padding: 20px 15px;
  border-radius: 15px;
  background-color: rgba(51, 102, 153, 0.25);
  color: #336699;
  font-weight: 600;
  font-style: italic;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .incident-form-info p {
  margin-bottom: 0;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper {
  margin: 15px auto 0;
  padding-left: 125px;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper .incident-image-wrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 100%;
  overflow: hidden;
  width: 300px;
  height: auto;
  max-height: 300px;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper .incident-image-wrapper:first-child {
  margin-right: 15px;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper .incident-image-wrapper .incident-image-wrapper-overlay {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper .incident-image-wrapper .incident-image-wrapper-overlay:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 15px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper .incident-image-wrapper .incident-image-wrapper-overlay:hover:before, .app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper .incident-image-wrapper .incident-image-wrapper-overlay:focus:before, .app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper .incident-image-wrapper .incident-image-wrapper-overlay:active:before {
  background: url("images/pictogrammes/close-white.svg") rgba(0, 0, 0, 0.7) no-repeat center center;
  background-size: 30px;
  cursor: pointer;
}

.app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper .incident-image-wrapper .incident-image-wrapper-overlay img {
  width: auto;
  max-width: 300px;
  height: auto;
  max-height: 300px;
  border-radius: 15px;
}

@media screen and (max-width: 360px) {
  .app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper .incident-image-wrapper .incident-image-wrapper-overlay img {
    max-width: 100%;
  }
}

.app .incident #incident-modal .modal .modal-scrollbar .form .submit-button-wrapper {
  padding-top: 40px;
}

@media screen and (max-width: 768px) {
  .app .incident #incident-modal .modal .modal-scrollbar .form .form-item-image-wrapper {
    display: block;
  }
  .app .incident #incident-modal .modal .modal-scrollbar .form .form-item-image-wrapper .form-item-image {
    margin: 0 auto 15px;
    width: 300px;
    max-width: 100%;
    min-width: 1px;
  }
}

@media screen and (max-width: 848px) {
  .app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper {
    padding: 0;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .app .incident #incident-modal .modal .modal-scrollbar .form .form-item-image-incident-wrapper {
    display: block;
  }
}

@media screen and (max-width: 723px) {
  .app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper .incident-image-wrapper:first-child {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 707px) {
  .app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper .incident-image-wrapper:first-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 448px) {
  .app .incident .card-header {
    padding-bottom: 0;
  }
  .app .incident .card-header h2, .app .incident .card-header .btn, .app .incident .card-header .pagination .pager, .app .pagination .incident .card-header .pager {
    margin-bottom: 10px;
  }
  .app .incident .table.table-incidents tbody .table-picto {
    width: 25px;
  }
  .app .incident .table.table-incidents tbody .animated-cell .table-picto {
    display: block;
    margin: 0 auto 5px;
  }
}

.app .my-account.grid.grid-3 {
      grid-template-areas: "child1 child2" "child3 child3";
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto 1fr;
      grid-template-rows: auto 1fr;
}

@media screen and (max-width: 1310px) {
  .app .my-account.grid.grid-3 {
        grid-template-areas: "child1" "child2" "child3";
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: auto 1fr 1fr;
        grid-template-rows: auto 1fr 1fr;
  }
}

.app .solde-tenants-global {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.app .my-owner-account .standfirst h3 {
  display: inline-block;
  margin-right: 75px;
  margin-bottom: 15px;
}

.app .my-owner-account .table-account .address {
  max-width: 320px;
}

.app .my-owner-account .table-account a.table-picto {
  height: 30px;
  background: url("images/pictogrammes/invoice-yellow.svg") no-repeat center center;
  background-size: contain;
}

.app .table-tenants a.table-picto {
  display: inline-block;
  text-indent: -9999px;
  height: 30px;
  width: 30px;
  background: url("images/pictogrammes/notebook-grey.svg") no-repeat center center;
  background-size: contain;
}

.app .table-tenants a.table-picto:hover, .app .table-tenants a.table-picto:focus, .app .table-tenants a.table-picto:active {
  background-image: url("images/pictogrammes/notebook-yellow.svg");
}

@media screen and (max-width: 576px), screen and (max-height: 448px) and (max-width: 992px) {
  .app .my-coowner-account .standfirst h3 {
    font-size: 20px;
  }
}

.my-managment.grid.grid-3 {
      grid-template-areas: "child1 child2" "child3 child3";
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr auto;
      grid-template-rows: 1fr auto;
}

.my-managment.grid.grid-3 .card {
  max-height: 450px;
}

.my-managment.grid.grid-3 .card.profit:not(.card-graph) {
  height: 475px;
}

.my-managment .card .card-body .standfirst {
  margin-bottom: 0;
  padding-right: 0 15px;
  text-align: center;
}

.my-managment .card .card-body .standfirst .item-selector, .my-managment .card .card-body .standfirst .app .item-select-disable, .app .my-managment .card .card-body .standfirst .item-select-disable {
  margin-right: 0;
  margin-left: 15px;
  margin-bottom: 0;
}

.my-managment .card .card-body .standfirst .item-selector:first-child, .my-managment .card .card-body .standfirst .app .item-select-disable:first-child, .app .my-managment .card .card-body .standfirst .item-select-disable:first-child {
  margin-left: 0;
}

.my-managment .budget-card {
  position: static;
}

.my-managment .budget-card .graph-circle {
  padding-bottom: 0;
  margin-bottom: 15px;
}

.my-managment .budget-card .caption {
  position: absolute;
  right: 0;
  left: calc(50% + 150px);
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.my-managment .budget-card .caption span {
  display: block;
}

@media screen and (max-width: 768px), screen and (max-width: 1920px) and (min-width: 1530px) {
  .my-managment .budget-card .caption {
    position: static;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    max-width: 100%;
    margin-bottom: 15px;
  }
  .my-managment .budget-card .caption span {
    display: inline;
  }
}

@media screen and (max-width: 576px) {
  .my-managment .budget-card .caption {
    font-size: 18px;
  }
}

.my-managment .budget-card #budget-modal .modal {
  height: auto;
  width: auto;
}

.my-managment .budget-card #budget-modal .modal .modal-scrollbar {
  padding: 30px 30px;
}

.my-managment .budget-card #budget-modal .modal .modal-scrollbar h2 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}

.my-managment .budget-card #budget-modal .modal .modal-scrollbar .modal-caption {
  text-align: center;
  font-style: italic;
  margin-bottom: 0;
}

.app .table-honoraries a.table-picto {
  display: inline-block;
  text-indent: -9999px;
  width: 25px;
  background: url("images/pictogrammes/pdf-yellow.svg") no-repeat center center;
  background-size: contain;
}

.app .profit .table-profit a.table-picto {
  height: 30px;
  background: url("images/pictogrammes/pdf-yellow.svg") no-repeat center center;
  background-size: contain;
}

@media screen and (max-width: 1500px) {
  .my-managment.grid.grid-3 {
        grid-template-areas: "child1" "child2" "child3";
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: auto;
        grid-template-rows: auto;
  }
}

.app .my-files .breadcrumb {
  margin-bottom: 30px;
  color: #336699;
  background-color: white;
  border: 2px solid #336699;
  border-radius: 10px;
}

.app .my-files .breadcrumb .breadcrumb-anchor {
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 500;
}

.app .my-files .breadcrumb .breadcrumb-anchor:hover, .app .my-files .breadcrumb .breadcrumb-anchor:focus, .app .my-files .breadcrumb .breadcrumb-anchor:active {
  text-decoration: underline;
}

.app .my-files .folder-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.app .my-files .folder-content .folder,
.app .my-files .folder-content .file {
  position: relative;
  width: 150px;
  margin-right: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
}

.app .my-files .folder-content .folder .picto-folder,
.app .my-files .folder-content .folder .picto-file,
.app .my-files .folder-content .file .picto-folder,
.app .my-files .folder-content .file .picto-file {
  padding: 0 15px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  max-width: 120px;
}

.app .my-files .folder-content .folder .folder-name,
.app .my-files .folder-content .folder .file-name,
.app .my-files .folder-content .file .folder-name,
.app .my-files .folder-content .file .file-name {
  display: block;
  font-weight: 500;
  margin: 0;
  text-align: center;
}

.app .my-files .folder-content .folder:hover .picto-folder,
.app .my-files .folder-content .folder:hover .picto-file,
.app .my-files .folder-content .file:hover .picto-folder,
.app .my-files .folder-content .file:hover .picto-file {
  padding: 10px 25px;
}

.app .my-files .folder-content .folder:before {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.app .my-files .folder-content .file .picto-file {
  margin-bottom: 10px;
}

.app .my-files .folder-content .file a:before {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .app .my-files .folder-content .folder,
  .app .my-files .folder-content .file {
    width: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .app .my-files .folder-content .folder .picto-folder,
  .app .my-files .folder-content .folder .picto-file,
  .app .my-files .folder-content .file .picto-folder,
  .app .my-files .folder-content .file .picto-file {
    max-width: 100%;
  }
}

.app .table.table-coowners td {
  vertical-align: top;
}

.app .table.table-coowners .td-label.lots {
  max-width: 600px;
}

.app .table.table-coowners a.table-picto {
  display: inline-block;
  margin: 3px;
  text-indent: -9999px;
  height: 30px;
  width: 30px;
  background: url("images/pictogrammes/notebook-grey.svg") no-repeat center center;
  background-size: contain;
}

.app .table.table-coowners a.table-picto:hover, .app .table.table-coowners a.table-picto:focus, .app .table.table-coowners a.table-picto:active {
  background-image: url("images/pictogrammes/notebook-yellow.svg");
}

.app .extended-link {
  color: #606060;
}

.app .table.table-bank tbody .solde {
  text-transform: uppercase;
  font-weight: 600;
  background-color: rgba(51, 102, 153, 0.3);
}

@media screen and (max-width: 448px) {
  #select-bank-account {
    width: 100%;
  }
}

.app .contact .download-btn {
  margin: 10px 0 30px;
}

.app .contact h3 {
  text-decoration: underline;
  margin-bottom: 20px;
}

.app .contact blockquote {
  margin-bottom: 30px;
}

.app .contact label {
  width: 120px;
}

.app .legal-notice h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.app .legal-notice h4 {
  padding-top: 20px;
  margin-bottom: 20px;
  text-decoration: underline;
}
