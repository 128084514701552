.my-managment
    &.grid.grid-3
        grid-template-areas: "child1 child2" "child3 child3"
        grid-template-columns: 1fr 1fr
        grid-template-rows: 1fr auto
        .card
            max-height: 450px
        .card.profit:not(.card-graph)
            // during graph loading
            height: 475px

    .card
        .card-body .standfirst
            margin-bottom: 0
            padding-right: 0 15px
            text-align: center
            .item-selector
                margin-right: 0
                margin-left: 15px
                margin-bottom: 0
                &:first-child
                    margin-left: 0

    .budget-card
        position: static
        .graph-circle
            padding-bottom: 0
            margin-bottom: 15px
        .caption
            position: absolute
            right: 0
            left: calc(50% + 150px)
            top: 50%
            transform: translateY(-50%)
            text-align: center
            font-size: 24px
            font-weight: $medium
            span
                display: block
            @media screen and (max-width: $screen-md), screen and (max-width: 1920px) and (min-width: 1530px)
                position: static
                transform: translateY(0)
                max-width: 100%
                margin-bottom: 15px
                span
                    display: inline
            @media screen and ( max-width: $screen-sm )
                font-size: 18px

        #budget-modal .modal
            height: auto
            width: auto
            .modal-scrollbar
                padding: 30px 30px
                h2
                    font-weight: $bold
                    text-align: center
                    margin-bottom: 30px
                .modal-caption
                    text-align: center
                    font-style: italic
                    margin-bottom: 0

.app .table-honoraries
    a.table-picto
        display: inline-block
        text-indent: -9999px
        width: 25px
        background: url('images/pictogrammes/pdf-yellow.svg') no-repeat center center
        background-size: contain
    

.app .profit .table-profit
    a.table-picto
        height: 30px
        background: url('images/pictogrammes/pdf-yellow.svg') no-repeat center center
        background-size: contain

@media screen and (max-width: $screen-xl + $aside_width)
    .my-managment.grid.grid-3
        grid-template-areas: "child1" "child2" "child3"
        grid-template-columns: 1fr
        grid-template-rows: auto
