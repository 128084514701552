.app
    .table.table-coowners
        td
            vertical-align: top
        .td-label.lots
            max-width: 600px
        a.table-picto
            display: inline-block
            margin: 3px
            text-indent: -9999px
            height: 30px
            width: 30px
            background: url('images/pictogrammes/notebook-grey.svg') no-repeat center center
            background-size: contain
            &:hover,
            &:focus,
            &:active
                background-image: url('images/pictogrammes/notebook-yellow.svg')

    .extended-link
        color: $grey
