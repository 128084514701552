// My Account
.app
    .my-account.grid.grid-3
        grid-template-areas: "child1 child2" "child3 child3"
        grid-template-columns: 1fr 1fr
        grid-template-rows: auto 1fr
        @media screen and (max-width: 1310px)
            grid-template-areas: "child1" "child2" "child3"
            grid-template-columns: 1fr
            grid-template-rows: auto 1fr 1fr

    .solde-tenants-global
        align-items: center
        display: flex

    // My Owner Account
    .my-owner-account
        .standfirst h3
            display: inline-block
            margin-right: 75px
            margin-bottom: 15px

        .table-account 
            .address
                max-width: 320px
            a.table-picto
                height: 30px
                background: url('images/pictogrammes/invoice-yellow.svg') no-repeat center center
                background-size: contain

    .table-tenants a.table-picto
        display: inline-block
        text-indent: -9999px
        height: 30px
        width: 30px
        background: url('images/pictogrammes/notebook-grey.svg') no-repeat center center
        background-size: contain
        &:hover,
        &:focus,
        &:active
            background-image: url('images/pictogrammes/notebook-yellow.svg')

@media screen and (max-width: $screen-sm), screen and (max-height: $screen-xs) and (max-width: $screen-lg)
    .app .my-coowner-account .standfirst h3
        font-size: 20px