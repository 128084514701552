.app .my-files
    .breadcrumb
        margin-bottom: 30px
        color: $blue
        background-color: white
        border: 2px solid $blue
        border-radius: 10px
        .breadcrumb-anchor
            cursor: pointer
            transition: all 0.3s
            font-weight: $medium
            &:hover,
            &:focus,
            &:active
                text-decoration: underline
    .folder-content
        display: flex
        flex-direction: row
        flex-wrap: wrap
        .folder,
        .file
            position: relative
            width: 150px
            margin-right: 30px
            margin-bottom: 30px
            cursor: pointer
            text-align: center
            font-size: 14px
            .picto-folder,
            .picto-file
                padding: 0 15px
                transition: all 0.2s
                max-width: 120px
            .folder-name,
            .file-name
                display: block
                font-weight: $medium
                margin: 0
                text-align: center
            &:hover .picto-folder,
            &:hover .picto-file
                padding: 10px 25px
        .folder:before
            content: ''
            position: absolute
            display: block
            left: 0  
            top: 0
            right: 0
            bottom: 0
            z-index: 1
        .file 
            .picto-file
                margin-bottom: 10px
            a:before
                content: ''
                position: absolute
                display: block
                left: 0  
                top: 0
                right: 0
                bottom: 0
                z-index: 1

@media screen and (max-width: $screen-md)
    .app .my-files .folder-content
        .folder,
        .file
            width: 100px
            margin-right: 10px
            margin-bottom: 10px
            .picto-folder,
            .picto-file
                max-width: 100%