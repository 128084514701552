.app .incident
    .card-header .btn
        margin-right: 20px

    .table.table-incidents tbody
        .table-picto
            transform: rotate(0deg)
            transition: all 0.5s
            &.clicked
                transform: rotate(-90deg)

    #incident-modal .modal
        width: 1100px
        max-width: 100%
        .modal-scrollbar
            padding: 30px 30px
            h2
                font-weight: $bold
                text-align: center
                margin-bottom: 30px

            .form
                margin: 0 auto

                label,
                .fake-label
                    padding-right: 30px
                    width: 125px

                label.btn
                    padding-right: 35px
                    width: auto

                .fake-label
                    display: inline-block
                    min-width: 125px

                .form-item-image-incident-wrapper
                    display: flex
                .form-item-image-wrapper
                    .fake-label
                        width: 140px
                    .form-item-image
                        flex-grow: 1
                        min-width: 300px
                        margin-right: 15px
                        background-color: $light_grey
                        border-radius: 15px
                        padding: 10px 15px
                        font-size: 16px
                        h3
                            text-align: center
                            font-size: 22px
                            font-weight: $bold
                        .form-group
                            text-align: center
                            margin-bottom: 0

                .incident-form-info
                    display: flex
                    justify-content: center
                    align-items: center
                    text-align: center
                    min-width: 0
                    font-size: 18px
                    vertical-align: middle
                    padding: 20px 15px
                    border-radius: 15px
                    background-color: transparentize($blue, 0.75)
                    color: $blue
                    font-weight: $bold
                    font-style: italic
                    p
                        margin-bottom: 0

                .incident-files-wrapper
                    margin: 15px auto 0
                    padding-left: 125px
                    .incident-image-wrapper
                        display: inline-flex
                        align-items: center
                        align-self: center
                        justify-content: center
                        max-width: 100%
                        overflow: hidden
                        width: 300px
                        height: auto
                        max-height: 300px
                        &:first-child
                            margin-right: 15px
                        .incident-image-wrapper-overlay
                            transition: all 0.3s
                            position: relative
                            &:before
                                content: ''
                                display: block
                                position: absolute
                                left: 0
                                right: 0
                                top: 0
                                bottom: 0
                                z-index: 1
                                border-radius: 15px
                                transition: all 0.3s
                            &:hover,
                            &:focus,
                            &:active
                                &:before
                                    background: url('images/pictogrammes/close-white.svg') transparentize(black, 0.3) no-repeat center center
                                    background-size: 30px
                                    cursor: pointer
                            img
                                width: auto
                                max-width: 300px
                                height: auto
                                max-height: 300px
                                border-radius: 15px
                                @media screen and (max-width: 360px)
                                    max-width: 100%

                .submit-button-wrapper
                    padding-top: 40px

@media screen and (max-width: $screen-md)
    .app .incident #incident-modal .modal .modal-scrollbar .form .form-item-image-wrapper
        display: block
        .form-item-image
            margin: 0 auto 15px
            width: 300px
            max-width: 100%
            min-width: 1px

@media screen and (max-width: 848px)
    .app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper
        padding: 0
        text-align: center

@media screen and (max-width: $screen-md)
    .app .incident #incident-modal .modal .modal-scrollbar .form .form-item-image-incident-wrapper
        display: block

@media screen and (max-width: 723px)
    .app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper .incident-image-wrapper:first-child
        margin-bottom: 15px

@media screen and (max-width: 707px)
    .app .incident #incident-modal .modal .modal-scrollbar .form .incident-files-wrapper .incident-image-wrapper:first-child
        margin-right: 0

@media screen and (max-width: $screen-xs)
    .app .incident
        .card-header
            padding-bottom: 0
            h2,
            .btn
                margin-bottom: 10px
        .table.table-incidents tbody
            .table-picto
                width: 25px
            .animated-cell .table-picto
                display: block
                margin: 0 auto 5px