.app header
    height: $header_height
    display: flex
    flex-direction: row
    align-items: center
    background-color: $blue
    color: white
    font-size: 23px
    white-space: nowrap
    a
        height: 100%
        padding: 20px 40px 15px
        color: white
        &:hover,
        &:focus,
        &:active,
        &.active
            color: darken($blue, 25)

    .menu-top
        display: flex
        margin-left: auto
        height: 100%
        max-width: 100%
    .menu-top a 
        display: inline-block
        text-decoration: none
        color: white
        &:hover,
        &:focus,
        &:active,
        &.active
            background-color: white
            color: $blue
            text-decoration: none

    .menu-top .user-menu
        position: relative
        max-width: 300px
        min-width: 0
        z-index: 100
        .user-menu-title
            display: inline-block
            position: relative
            height: 100%
            z-index: 1
            background-color: $blue
            color: white
            padding: 20px 20px 15px
            transition: all 0.3s
            overflow: hidden
            text-overflow: ellipsis
            max-width: 100%
            min-width: 200px
            text-align: center
            &:hover,
            &:focus,
            &:active
                background-color: white
                color: $blue
            
        .user-menu-hover
            position: absolute
            right: 0
            left: 0
            top: 100%
            height: 0
            width: 0
            z-index: -1
            background-color: transparent
            transition: all 0.3s
            opacity: 0
            transform: translateY(-101%)
            font-size: 18px
            font-weight: $medium
            overflow: hidden
            a
                color: $blue
                padding: 20px 10px 10px
                display: block
                text-align: center
                &:hover,
                &:focus,
                &:active,
                &.active
                    background-color: $blue
                    color: white
            ul
                margin: 0
                padding: 0
                li
                    list-style-type: none

        &:hover,
        &:focus,
        &:active,
        &.active
            .user-menu-title
                background-color: white
                color: $blue
            .user-menu-hover
                display: block
                opacity: 1
                background-color: white
                transform: translateY(0)
                box-shadow: 0 0 5px 0 rgba(0,0,0,0.3)
                white-space: normal
                height: auto
                width: auto

    .property-link
        height: 100%
        padding: 0
        margin: 0
        font-size: inherit
        font-weight: inherit
        color: white
        line-height: inherit
        overflow: hidden
        text-overflow: ellipsis
        a
            display: inline-block
            text-decoration: none
            &:hover,
            &:focus,
            &:active
                background-color: white
                color: $blue

    .header-text 
        display: inline-block
        padding: 20px 40px 15px
        margin: 0
        font-style: italic
        a
            text-decoration: underline
            padding: 0

@media screen and (max-width: $screen-xl)
    .app header .header-text 
        display: none

@media screen and (max-width: $screen-lg)
    .app header
        padding-left: 70px
        a
            padding-left: 20px
            padding-right: 20px 
        .menu-top 
            > a 
                display: none
            .user-menu .user-menu-title
                padding-left: 20px
                padding-right: 20px 

@media screen and (max-width: $screen-sm) 
    .app header .property-link
        display: none