// Graph

.app .card .card-body.card-graph
    padding-left: 0
    padding-right: 0

.graph
    margin: 0 auto
    *
        font-family: $font-family-futura
.graph.graph-col
    max-width: 100%
    margin-bottom: 15px
    height: 300px
    &.graph-1-col
        width: 200px
        min-width: 200px
    &.graph-2-col
        width: 400px
        min-width: 400px
    &.graph-3-col
        width: 600px
        min-width: 600px

.graph.graph-circle
    max-width: 100%
    height: auto
    width: 250px

.highcharts-tooltip
    > span
        background-color: white
        border: 1px solid $light_grey
        padding: 5px
        box-shadow: 0 0 3px 0 rgba(0,0,0,0.3)
        position: relative
        z-index: 1
        font-size: 14px !important
        ul
            list-style-type: none
            padding: 0
            margin: 0
            li:first-child:not(:last-child)
                margin-bottom: 5px
            li
                position: relative
                padding-left: 10px
            li:before
                content: ''
                position: absolute
                left: 0
                top: calc(50% - 2px)
                display: block
                width: 5px
                height: 5px
                background-color: $blue
                border-radius: 50%
                
@media screen and ( max-width: $screen-sm )
    .graph.graph-col
        height: 200px
        &.graph-1-col
            width: 150px
            min-width: 150px
        &.graph-2-col
            width: 300px
            min-width: 300px
        &.graph-3-col
            width: 450px
            min-width: 450px
        .highcharts-label text
            font-size: 14px !important

    .graph.graph-circle
        max-width: 100%
        height: auto
        width: 150px
        .progressbar-text
            font-size: 18px !important

@media screen and (max-height: $screen-xs) and (max-width: $screen-lg)
    .graph.graph-col
        height: 200px
        .highcharts-label text
            font-size: 14px !important

    .graph.graph-circle
        max-width: 100%
        height: auto
        width: 150px
        .progressbar-text
            font-size: 18px !important